<template>
    <div>
        <el-row>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p belge-upload">
                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.belge")'
                              prop="belge">:
                            <el-upload
                                    class="upload-demo"
                                    action=""
                                    multiple
                                    :limit="10"
                                    :file-list="fileListMain"
                                    :auto-upload="false"
                                    :on-change="fileUpload"
                                    :on-remove="fileRemove"
                                    list-type="picture-card">
                                <!-- <el-button size="mini" style="width: 150px;"
                                           type="success">
                                    Dosya
                                    Yükle
                                </el-button> -->
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                           <!-- <el-button style="margin-top: 15px;width: 150px;"
                                       type="primary"
                                       v-on:click="serverDocumentList"
                                       @click="dialogDocServerVisible = true; "
                                       size="mini">
                                Sunucudan
                                Seç
                            </el-button> -->
                </el-form-item>
            </el-col>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <ul>
                    <li v-for="(item,index) in belgeIsimListe" :key="index" >{{item}}<span class="close" @click="fileRemoveName(item)">&times;</span></li>
                </ul>
            </el-col>
        </el-row>
     <!--   <el-dialog
                title='Sunucudan Belge Seç'
                :visible.sync="dialogDocServerVisible"
                width="50%"
                append-to-body
                center>
            <el-upload
                    action="#"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    class="upload-demo"
                    :on-preview="fileUploadtoServer"
                    :auto-upload="false"
                    :file-list="fileListServer">
            </el-upload>
        </el-dialog> -->
    </div>
</template>

<script>
    // import belgeService from "../WSProvider/BelgeService.js"
    import notification from '../notification'
    import EventBus from '@/components/event-bus'
    import functions from '../functions'

    export default {
        name: "Belge",
        props: ['functionName', 'belgeList', 'link', 'selectedsFromServer'],
        watch: {
            belgeList: function (val) {
                this.belgeIsimListe = [];
                this.fileListMain = val
                this.fileListMain.forEach(element => {
                    this.belgeIsimListe.push(element.name);
                })
            },
            link: function(val){
                this.belgeLink = val; // belgeLink belgeleri  silerken gerekli
            }
        },
        data(){
            return{
                loading : false,
                fileListMain: [],
                fileListServer: [],
                dialogDocServerVisible: false,
                gonderilecekBelge: [],
                belgeIsimListe: [],
                belgeLink: [],
            }
        },
        methods : {
            fileRemove(file) {
                var result = -1;
                var bool = false;

                //resim eger mevcutsa size özelliği obje içinde gelmiyor. Bu yuzden size değişkeni yoksa sadece sildirme işlemi yapılır.
                this.fileListMain.forEach((el, index) => {
                    if(!el.hasOwnProperty("size") &&   el.tmp_name === file.tmp_name){
                        bool = true;
                        result = index;
                        this.removeFileFromDB(el.tmp_name,result);
                    }
                })

                if(!bool){
                    var index = this.fileListMain.indexOf(file)
                    this.fileListMain.splice(index, 1);
                    this.belgeIsimListe.splice(index, 1);
                    if(this.gonderilecekBelge.length > 0)  this.gonderilecekBelge.splice(index,1)
                }
            },

            fileRemoveName(name){
                var index = this.belgeIsimListe.indexOf(name)
                var result = 0;
                var result2 = 0;
                var bool = false;

                this.fileListMain.forEach((el, index) => {
                    if( !el.hasOwnProperty("size") && el.tmp_name === name){
                        result = index;
                        this.removeFileFromDB(el.tmp_name,result);
                        bool = true;
                    }
                })
                if(!bool){
                    this.gonderilecekBelge.forEach((el, index) => {
                        if(el.tmp_name === name){
                            result2 = index;
                        }
                    })
                }

                this.fileListMain.splice(result, 1);
                this.gonderilecekBelge.splice(result2,1);
                this.belgeIsimListe.splice(index,1);
            },

            removeFileFromDB(name, index){
                this.fileListMain.splice(index, 1);
                // belgeService.belgeAdiylaSil(this.belgeLink, name).then(response => {
                //     if(response.status === 200){
                //         if(response.data.data){
                //             notification.Status('success', this, 'Belge Başarıyla Kaldırıldı.');
                //             localStorage.setItem("userDataBGSurec", response.token)
                //         }
                //         else{
                //             notification.Status('success', this, 'İşlem Sırasında Bir Hata Oluştu.');
                //         }
                //     }
                //     else if(response.status === 401){
                //         notification.Status("warning", this, "Oturum süreniz sona ermiştir.Tekrar giriş yapınız.")
                //         EventBus.$emit("userLogout", true)
                //     }
                //     else if(response.status === 403){
                //         notification.Status("warning", this, "Bu işlem için yetkiniz bulunmamaktadır.")
                //     }
                // })
            },

            fileUpload(file, fileList) {
                var type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                var typeExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (file.raw["type"] === type || file.raw["type"] === typeExcel || file.raw["type"] === 'application/vnd.ms-excel' || file.raw["type"] === 'application/msword' || file.raw["type"] === 'application/pdf') {
                    var item = {
                        name: file.name,
                        url: '',
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.fileListMain.push(item);
                    file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor
                    this.gonderilecekBelge.push(file.raw);

                    this.belgeIsimListe.push(file.name);
                    EventBus.$emit(this.functionName, true, this.gonderilecekBelge);
                    fileList.pop();

                } else {
                    fileList.pop();
                    notification.Status('warning', this, 'Yalnızca ".pdf", ".xls", "xlsx", ".doc" ve ".docx" uzantılı görsel seçiniz!!');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ul {
        list-style-type: none;
        padding: 0;
        li {
            border: 1px solid #ddd;
            margin-top: -1px; /* Prevent double borders */
            background-color: #F5F7FA;
            padding: 8px;
            text-decoration: none;
            color: black;
            display: block;
            position: relative;
        }
        margin: 0;
        margin-bottom: 2% !important;
    }


    ul li:hover {
        background-color: #e6e9ed;
    }

    .close {
        cursor: pointer;
        position: absolute;
        font-size: 16px;
        top: 50%;
        right: 0%;
        padding-right: 10px;
        transform: translate(0%, -50%);
    }

    
</style>

<style lang="scss">
    .belge-upload{

        // .el-upload{
        //     border: 0px !important;
        // }

        

        .el-upload-list__item{
            img{
                content:url(../assets/images/document.jpg);
            }
            
        }
    }
</style>